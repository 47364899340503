import {  gql } from '@apollo/client';
import { useQuery } from '@apollo/client';


const Sales = gql`
    query get_sales($month: Boolean!  $year:Boolean!){
        get_sales(filter:{
            year: $year
            month: $month
        }) {
            amount
            year
            month
            name
            count
        }
    }
`


const SalesTotalGQL = gql `
    query get_sales_count {
        getTotalSales
    }
`

const GetSalesTotal = () => {
    const { loading, error, data } = useQuery(SalesTotalGQL);
    return { loading, error, data };
}


const RecentActivitiesGQl = gql `
    query RecentActivities {
        recentActivities {
            customerName
            amount
            activityDate
            source
            amountStatus
        }
    }
`
const GetRecentActivities = () => {
    const { loading, error, data } = useQuery(RecentActivitiesGQl);
    return { loading, error, data };
}

export {
    Sales,
    GetSalesTotal,
    GetRecentActivities
}