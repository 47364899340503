import { useEffect } from "react";
import { GetRecentActivities } from "../../../components/Appolo/sales.query"
import { useDispatch } from "react-redux";
import { formatDate } from "../../../utils/helpers";

const { Table, Button, CardHeader, Row, Card, Spinner } = require("reactstrap")

function getSourceClass(source) {
  switch (source) {
    case "payments":
      return "primary";
    case "new-loan":
      return "success";
    default:
      return "text-black";
  }
}

const RecentActivities = () => {
  const dispatch = useDispatch();

  const { loading, data, err } = GetRecentActivities()
  // useEffect(() => {
  //   // runQuery()
  // }, [dispatch]);


  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Recent Activities</h3>
          </div>
          {/* <div className="col text-right">
            <Button
              color="primary"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              size="sm"
            >
              See all
            </Button>
          </div> */}
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Activity</th>
            <th scope="col">Customer</th>
            <th scope="col">Amount</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            loading ? (
              <Spinner className="text-default" key={`key-1`}>
                <span className=" sr-only">Loading...</span>
              </Spinner>
            ) : (
              // loop over data
              data?.recentActivities?.map((activity, index) => (
                <tr key={`key-${index}`}>
                  {/* show different color for source */}
                  <td>
                <Button size="sm" color={getSourceClass(activity.source)} >{activity.source}</Button>

                  </td>
                  <td>{activity.customerName}</td>
                  <td>
                    <i className={`fas fa-arrow-${activity.amountStatus == "credit" ? "up" : "down"} text-${activity.amountStatus == "credit" ? "success" : "warning"} mr-3`} />{" "}
                    {activity.amount}
                  </td>
                  <td>
                    {formatDate(activity.activityDate)}
                  </td>
                </tr>
              ))
            )
          }
          {/* <tr>
            <th scope="row">investment</th>
            <td>self</td>
            <td>
              <i className="fas fa-arrow-down text-warning mr-3" />{" "}
              15000
            </td>
            <td>
              10/09/2023
            </td>
          </tr>
          <tr>
            <th scope="row">Emai received</th>
            <td>John Doe</td>
            <td>
              <i className="fas fa-arrow-up text-success mr-3" />{" "}
              3000
            </td>
            <td>
              10/09/2023
            </td>
          </tr> */}
        </tbody>
      </Table>
    </Card>
  )
}

export default RecentActivities